.icons-2 {
    position: fixed;
    bottom: 100px;
    right: 15px;
    height: 45px;
    width: 45px;
    background: #03a9f3;
    z-index: 99;
    -webkit-box-shadow: 0 0 31px 6px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 31px 6px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 31px 6px rgba(0, 0, 0, 0.17);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 100%;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -ms-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1.5s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInUp;

    color: unset;
}


.icons-4 {
    position: fixed;
    bottom: 215px;
    right: 15px;
    height: 45px;
    width: 45px;
    background: #03a9f3;
    z-index: 99;
    -webkit-box-shadow: 0 0 31px 6px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 31px 6px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 31px 6px rgba(0, 0, 0, 0.17);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 100%;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -ms-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInUp;

    color: unset;
}